<script setup>
import { useAuthStore } from '@/store/auth'
import { useRouter } from 'vue-router'

const auth = useAuthStore()
const router = useRouter()
auth.logout()
router.push({ name: 'Login' })
</script>

<template>
  <div
    class="flex justify-content-center align-items-center"
    style="height: 100vh"
  >
    Logout...
  </div>
</template>
